<template>
  <canvas ref="canvas" width="32" height="32" />
</template>

<script lang="ts" setup>
import { decode } from 'blurhash';

const props = defineProps<{
  hash: string;
}>();

const canvas = ref<HTMLCanvasElement | null>(null);

onMounted(() => {
  const pixels = decode(props.hash, 32, 32);
  const imageData = new ImageData(pixels, 32, 32);
  const context = canvas.value!.getContext('2d');
  context!.putImageData(imageData, 0, 0);
});
</script>
